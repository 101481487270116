<template>
  <b-card-body>
    <b-card-title>Webchat Channel</b-card-title>
    <b-form @submit.prevent="createChannel">
      <b-form-group label-for="website-name" label="Website Name" label-class="font-weight-bold">
        <b-form-input type="text" placeholder="Insert your website name" id="website-name" v-model="configuration.website_name" required />
      </b-form-group>
      <b-form-group label-for="website-domain" label="Website Domain" label-class="font-weight-bold">
        <b-form-input type="text" placeholder="Insert your domain name" id="website-domain" v-model="configuration.website_domain" required />
      </b-form-group>
      <b-form-group label-for="widget-color" label="Widget Color" label-class="font-weight-bold">
        <el-color-picker v-model="configuration.widget_color" required ></el-color-picker>
      </b-form-group>
      <b-form-group label-for="welcome-heading" label="Welcome Heading" label-class="font-weight-bold">
        <b-form-input type="text" placeholder="Insert your welcome heading" id="welcome-heading" v-model="configuration.welcome_heading" required />
      </b-form-group>
      <b-form-group label-for="welcome-tagline" label="Welcome Tagline" label-class="font-weight-bold">
        <b-form-input type="text" placeholder="Insert your welcome tagline" id="welcome-tagline" v-model="configuration.welcome_tagline" />
      </b-form-group>
      <b-button type="submit" variant="primary">{{ $t('general.submit') }}</b-button>
    </b-form>
  </b-card-body>
</template>
<script>
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'AddTelegramChannel',
  data() {
    return {
      configuration: {
        website_name: '',
        website_domain: '',
        widget_color: '',
        welcome_heading: '',
        welcome_tagline: '',
      },
      timeout: null,
    };
  },
  methods: {
    createChannel() {
      this.$emit('createChannel', this.configuration);
    },
    handlerInputToken() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        console.log('testing...');
        const regex = /(\w+):(\w+)/g;
        if (regex.test(this.configuration.token)) {
          // Validate token
          this.checkProfile();
        }
      }, 1000);
    },
    async checkProfile() {
      this.$parent.showLoader();
      await channelsAPI.checkTelegramProfile({
        type: 'telegram',
        token: this.configuration.token,
        workspace_id: this.$parent.activeWorkspace._id,
      }).then(async (response) => {
        await popupErrorMessages(response);
        const { profile } = response.data;
        this.configuration.bot_username = profile.username;
      }).catch(() => {});
      this.$parent.hideLoader();
    },
  },
};
</script>
